/* eslint-disable no-param-reassign */
import React, {
  ChangeEvent, useEffect, useMemo, useRef, useState
} from 'react';

import Image from 'components/atoms/Image';
import { formatBytes } from 'utils/functions';

interface UploadFileProps {
  onChange?(file?: File): void;
  value?: File;
}

const UploadFile: React.FC<UploadFileProps> = ({ onChange, value }) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const ref = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (ref.current) ref.current.click();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!(e.target.files && e.target.files.length > 0)) return;
    setFile(e.target.files[0]);
    e.target.value = '';
  };

  const previewUrl = useMemo(() => {
    let url = '';
    if (file) {
      url = URL.createObjectURL(file);
    }
    return url;
  }, [file]);

  useEffect(() => {
    if (onChange && file) {
      onChange(file);
    }
  }, [file, onChange]);

  useEffect(() => {
    setFile(value);
  }, [value]);

  return (
    <div className="dropzone dz-clickable" id="careerDetail__form" role="button" onClick={handleClick}>
      <input type="file" ref={ref} name="file" accept=".png,.jpg,.gif,.bmp,.jpeg,.pdf,.docx" onChange={onFileChange} />
      <div className="dz-default dz-message"><span>Drop files here to upload</span></div>
      {file && (
        <div className="dz-preview dz-file-preview">
          <div className="dz-image" style={{ width: 120 }}>
            {
              ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type) && <Image imgSrc={previewUrl} ratio="1x1" alt="preview" />
            }
          </div>
          <div className="dz-details">
            <div className="dz-size">
              <span>
                <strong>{formatBytes(file.size)}</strong>
              </span>
            </div>
            <div className="dz-filename"><span data-dz-name="">{file.name}</span></div>
          </div>
          <button
            type="button"
            className="dz-remove"
            aria-label="remove file"
            onClick={(e) => {
              e.stopPropagation();
              setFile(undefined);
            }}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadFile;

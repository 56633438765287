import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HeaderFooter, SystemDetail } from 'services/models';
import { getHeaderFooterService, getPageStaticService, getSystemGeneraService } from 'services/system';
import {
  HeaderBlockType, FooterBlockType, PageStaticTypes, StickyMenuBlockType
} from 'services/system/types';

interface SystemState {
  loadingInit: boolean;
  headerFooter?: HeaderFooter<HeaderBlockType, FooterBlockType, StickyMenuBlockType>;
  systemData?: SystemDetail;
  staticPage?: PageStaticTypes[];
}

const initialState: SystemState = {
  loadingInit: true,
  headerFooter: undefined,
  systemData: undefined
};

export const getHeaderFooterAsync = createAsyncThunk<
  HeaderFooter<HeaderBlockType, FooterBlockType, StickyMenuBlockType>>(
    'systemReducer/getHeaderFooterAsync',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getHeaderFooterService();
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const getStaticPageAsync = createAsyncThunk<PageStaticTypes[]>(
  'systemReducer/getStaticPageAsync',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getPageStaticService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSystemGeneralAsync = createAsyncThunk<SystemDetail>(
  'systemReducer/getSystemGeneralAsync',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSystemGeneraService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    initialDone: ($state) => {
      $state.loadingInit = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getHeaderFooterAsync.fulfilled, ($state, action) => {
      $state.headerFooter = action.payload;
    });
    builder.addCase(getSystemGeneralAsync.fulfilled, ($state, action) => {
      $state.systemData = action.payload;
    });
    builder.addCase(getStaticPageAsync.fulfilled, ($state, action) => {
      $state.staticPage = action.payload;
    });
  },
});

export const { initialDone } = systemSlice.actions;

export default systemSlice.reducer;

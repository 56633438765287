/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useCallback,
  useEffect, useRef, useState
} from 'react';
import { useParams } from 'react-router-dom';

import logo from 'assets/images/logo.svg';
import Link from 'components/atoms/Link';
import Loading from 'components/atoms/Loading';
import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import useWindowScroll from 'hooks/useWindowScroll';
import { MenuListItems } from 'services/models';
import { getSearchAllService } from 'services/search';
import { SearchAllDataType } from 'services/search/types';
import { checkExternalUrl } from 'utils/functions';
import { checkActiveMenu, returnRouteMenu } from 'utils/menu';

interface HeaderProps {
  logoSrc?: string;
  menuData: MenuListItems[];
  RFPLink?: {
    text?: string;
    href?: string;
  }
  preSlug?: string;
  handleSearch: (val: string) => void;
}

interface MenuProps {
  listMenus: MenuListItems[];
  isSubmenu?: boolean;
  idActiveMenu?: number;
  idActiveSubMenu?: number
}

interface SlideMenuProps {
  listMenus: MenuListItems[];
  isSubmenu?: boolean;
  idActiveSlideMenu?: number;
  idActiveSubMenu?: number;
  handleSetActiveSlideMenu?: (id: number) => void;
  handlePress?: () => void;
}

const Menu: React.FC<MenuProps> = ({
  listMenus, idActiveMenu = -1, idActiveSubMenu = -1, isSubmenu
}) => (
  <ul className={`${!isSubmenu ? 'nav' : 'sub'}`}>
    {listMenus.map((item) => (
      <li
        key={
          `${!isSubmenu ? 'menu-item' : 'submenu-item'}-${item.id?.toString()}-${item.title?.toString()}`
        }
        className={`${!isSubmenu ? 'menu-item' : 'submenu-item'}${(!isSubmenu ? idActiveMenu : idActiveSubMenu) === item.id ? ' active' : ''}${item.subMenu && item.subMenu.length > 0 ? ' hasChild' : ''}`}
      >
        <Link
          useExternal={checkExternalUrl(returnRouteMenu(item))}
          href={returnRouteMenu(item)}
        >
          {item.title}
        </Link>
        {item.subMenu && item.subMenu.length > 0 && (
          <Menu listMenus={item.subMenu} idActiveSubMenu={idActiveSubMenu} isSubmenu />
        )}
      </li>
    ))}
  </ul>
);

const SlideMenu: React.FC<SlideMenuProps> = ({
  listMenus,
  idActiveSlideMenu = -1, idActiveSubMenu = -1, isSubmenu, handleSetActiveSlideMenu, handlePress
}) => (
  <ul className={`${!isSubmenu ? 'slideMenu__nav' : 'sub'}`}>
    {listMenus.map((item) => (
      <li
        key={`slideMenu-item-${item.id?.toString()}-${item.title?.toString()}`}
        className={`slideMenu-item${item.subMenu && item.subMenu.length > 0 ? ' hasChild' : ''}${(!isSubmenu ? idActiveSlideMenu : idActiveSubMenu) === item.id ? ' active' : ''}`}
      >
        <Link
          useExternal={checkExternalUrl(returnRouteMenu(item))}
          href={returnRouteMenu(item)}
          handleClick={handlePress}
        >
          {item.title}
        </Link>
        {item.subMenu && item.subMenu.length > 0 && (
          <>
            <SlideMenu
              handlePress={handlePress}
              listMenus={item.subMenu}
              isSubmenu
              idActiveSlideMenu={idActiveSlideMenu}
              idActiveSubMenu={idActiveSubMenu}
            />
            {item.subMenu.length > 0 && (
              <span
                className="showChild"
                onClick={() => {
                  if (!handleSetActiveSlideMenu) return null;
                  if (item.id && idActiveSlideMenu !== item.id) {
                    return handleSetActiveSlideMenu(item.id);
                  }
                  return handleSetActiveSlideMenu(-1);
                }}
              />
            )}
          </>
        )}
      </li>
    ))}
  </ul>
);

const Header: React.FC<HeaderProps> = ({
  logoSrc, menuData, RFPLink, preSlug, handleSearch
}) => {
  const { slug } = useParams<{ slug: string }>();
  const [openSlideMenu, setOpenSlideMenu] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [idActive, setIdActive] = useState<number>(-1);
  const [idSubActive, setIdSubActive] = useState<number>(-1);
  const [idActiveSlideMenu, setActiveSlideMenu] = useState<number>(-1);
  const [searchValue, setSearchValue] = useState<string>('');
  const slideMenuRef = useRef<HTMLDivElement>(null);
  const hambugerRef = useRef<HTMLButtonElement>(null);
  const searchRef = useRef<HTMLFormElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [resultSearch, setResultSearch] = useState<SearchAllDataType[]>();
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  useClickOutside([slideMenuRef, hambugerRef], () => {
    if (openSlideMenu) {
      setOpenSlideMenu(false);
    }
  });

  useWindowScroll(() => {
    setScrollTop(window.scrollY);
  });

  useClickOutside([searchRef], () => {
    if (openSearch) setOpenSearch(false);
  });

  useEffect(() => {
    checkActiveMenu(
      slug || '',
      (id) => {
        setIdActive(id);
        setActiveSlideMenu(id);
      },
      (id) => {
        setIdSubActive(id);
      },
      menuData,
      preSlug,
    );
  }, [menuData, slug, preSlug]);

  useEffect(() => {
    if (idActive !== -1 && openSlideMenu) setActiveSlideMenu(idActive);
    if (!openSlideMenu) setActiveSlideMenu(-1);
  }, [idActive, openSlideMenu]);

  useDebounce(async () => {
    try {
      if (!searchValue) {
        setResultSearch([]);
        return;
      }
      setIsSearchLoading(true);
      const res = await getSearchAllService({
        keyword: searchValue,
        limit: 20,
        locale: 'en',
        page: 1,
      });
      setResultSearch(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearchLoading(false);
    }
  }, 1000, [searchValue]);

  const mapLinkToRedirect = useCallback((searchDataParam: SearchAllDataType) => {
    if (searchDataParam.searchData?.objectType === 'page') {
      return searchDataParam.referenceData?.slug ? `/${searchDataParam.referenceData.slug}` : '';
    }

    if (searchDataParam.searchData?.objectType === 'job') {
      return searchDataParam.referenceData?.slug ? `/opening-vacancies/${searchDataParam.referenceData.slug}` : '';
    }

    return searchDataParam.referenceData?.slug ? `/${searchDataParam.referenceData.slug}` : '';
  }, []);

  return (
    <header className={`header navbar ${scrollTop > 0 ? 'affix' : 'affix-top'}`} id="top">
      <div className="header__wrapper">
        <div className="header__left t-header_left">
          <h1 className="t-header_left-heading">
            <Link
              customClassName="header__logo"
              href="/"
              title="drDigital"
            >
              <img src={logoSrc || logo} alt="digital.vn" width={125} height={54} />
            </Link>
          </h1>
        </div>
        {/* <!--Nav Header--> */}
        {menuData.length > 0 && (
          <>
            <nav className="header__right">
              <Menu
                listMenus={menuData}
                idActiveMenu={idActive}
                idActiveSubMenu={idSubActive}
              />
              {/* <!-- / nav --> */}
              <div className="header__tools">
                {RFPLink && (
                  <Link
                    useExternal={checkExternalUrl(RFPLink.href)}
                    href={RFPLink.href}
                    customClassName="header__tools__submit btn"
                  >
                    {RFPLink.text}
                  </Link>
                )}
                <form
                  ref={searchRef}
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (handleSearch) handleSearch(searchValue);
                  }}
                  className={`header__tools__search dropdown${openSearch ? ' open' : ''}`}
                >
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownSearch"
                    onClick={() => setOpenSearch(!openSearch)}
                    aria-label="icon search"
                  >
                    <i className="icon_search" />
                  </button>
                  <div
                    className="dropdown-menu"
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button className="btn" type="submit" aria-label="Icon search">
                      <i className="icon_search" />
                    </button>
                    <div className="t-header_resultSearch">
                      {
                        isSearchLoading ? (
                          <div className="t-header_resultSearch-loading">
                            <Loading isShow size="small" />
                          </div>
                        ) : resultSearch?.map((item) => (
                          <Link
                            key={item.searchData.title}
                            href={mapLinkToRedirect(item)}
                          >
                            <div
                              className="t-header_resultSearch-item"
                              onClick={() => setOpenSearch(!openSearch)}
                            >
                              {item.searchData.title}
                            </div>
                          </Link>
                        ))
                      }
                    </div>
                  </div>
                </form>
                <button
                  ref={hambugerRef}
                  type="button"
                  aria-label="humbuger"
                  className={`header__tools__menu${openSlideMenu ? ' active' : ''}`}
                  onClick={() => {
                    setOpenSlideMenu(!openSlideMenu);
                  }}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </button>
              </div>
            </nav>
            {/* <!--/ main menu--> */}
          </>
        )}
      </div>
      <div
        ref={slideMenuRef}
        className={`slideMenu${openSlideMenu ? ' show' : ''}`}
      >
        <div className="slideMenu__inner">
          <SlideMenu
            listMenus={menuData}
            idActiveSlideMenu={idActiveSlideMenu}
            idActiveSubMenu={idSubActive}
            handleSetActiveSlideMenu={(id) => setActiveSlideMenu(id)}
            handlePress={() => setOpenSlideMenu(false)}
          />
        </div>
      </div>
      {/* <!-- / slide Menu --> */}
    </header>
  );
};

Header.defaultProps = {
  menuData: [],
};

export default Header;

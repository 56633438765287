import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getErrorService from 'services/Error';
import { ErrorBlock } from 'services/Error/types';

interface ErrorPageState {
  errorPages: ErrorBlock;
}

const initialState: ErrorPageState = {
  errorPages: {},
};

export const getErrorPageAction = createAsyncThunk<ErrorBlock>(
  'errorPageReducer/getErrorPageAction',
  async (_, { rejectWithValue }): Promise<ErrorBlock | any> => {
    try {
      const res = await getErrorService();
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const errorPageSlice = createSlice({
  name: 'errorPageReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getErrorPageAction.fulfilled, ($state, action) => {
      $state.errorPages = action.payload;
    });
  },
});

export default errorPageSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getMenuService } from 'services/menu';
import { MenuList } from 'services/models';

interface MenuState {
  menus: MenuList[];
}

const initialState: MenuState = {
  menus: []
};

export const getMenuDataAsync = createAsyncThunk<MenuList[]>(
  'menuReducer/getMenuDataAsync',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getMenuService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const menuSlice = createSlice({
  name: 'menuReducer',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getMenuDataAsync.fulfilled, ($state, action) => {
      $state.menus = action.payload;
    });
  },
});

export default menuSlice.reducer;

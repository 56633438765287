import React, { FunctionComponent } from 'react';

import { TemplateCodes } from 'navigations';
import { PageDetail } from 'services/models';

export type RenderPageProps = {
  pageData?: PageDetail<any>;
};

const RenderPage: React.FC<RenderPageProps> = ({ pageData }) => {
  if (!pageData) return <>NOTFOUND</>;

  const Component = TemplateCodes.find(
    (template) => template.templateCode === pageData?.pageData.templateCode,
  )?.component;

  if (Component) {
    return React.createElement<
      PageDetail<any>>(Component as FunctionComponent, pageData);
  }

  return <>NOTFOUND</>;
};

export default RenderPage;

import { SearchAllDataType } from './types';

import axiosInstance from 'services/common/instance';

export const getSearchAllService = async (params: BaseParamsTypes): Promise<APIPaginationResponse<
  SearchAllDataType[]>> => {
  const response = await axiosInstance.get('search-all', { params });
  return response.data;
};

export const Placeholder = '';

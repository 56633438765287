import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const phoneRegExp = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/bmp',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const SUPPORTED_PDF_DOC = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const useSchemas = () => {
  const { t } = useTranslation();

  const formGeneralContact = yup.object({
    name: yup.string().required(`${t('schema.required')}`),
    email: yup.string().email(`${t('schema.invalidEmail')}`).required(`${t('schema.invalidRequiredEmail')}`),
  });

  const requestTeamSchema = yup.object({
    name: yup.string().required(`${t('schema.required')}`),
    email: yup.string().required(`${t('schema.requiredEmail')}`).email(`${t('schema.invalidEmail')}`),
    phone: yup.string().matches(phoneRegExp, `${t('schema.notMatchFormat')}`),
    company: yup.string().required(`${t('schema.required')}`),
    teamSizeId: yup.number().required(`${t('schema.required')}`),
    requirementDetail: yup.string().required(`${t('schema.required')}`),
    duration: yup.number(),
  });

  const rfpContactSchema = yup.object({
    file: yup.mixed().test('file', `${t('schema.invalidEmail')}`, (file: any) => {
      if (!file) return true;
      return SUPPORTED_FORMATS.includes(file.type);
    }).nullable(),
    firstName: yup.string().required(`${t('schema.required')}`),
    lastName: yup.string().required(`${t('schema.required')}`),
    email: yup.string().required(`${t('schema.requiredEmail')}`).email(`${t('schema.invalidEmail')}`),
    phone: yup.string().matches(phoneRegExp, `${t('schema.notMatchFormat')}`),
    company: yup.string(),
    country: yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    }),
    solutions: yup.array(yup.string()),
    desc: yup.string(),
  });

  const openVacanciesDetailSchema = yup.object({
    file: yup.mixed().required(`${t('schema.requiredFile')}`).test('file', `${t('schema.notMatchFormat')}`, (file: any) => {
      if (!file) return true;
      return SUPPORTED_PDF_DOC.includes(file.type);
    }).nullable(),
    firstName: yup.string().required(`${t('schema.required')}`),
    lastName: yup.string().required(`${t('schema.required')}`),
    email: yup.string().required(`${t('schema.requiredEmail')}`).email(`${t('schema.invalidEmail')}`),
    phone: yup.string().matches(phoneRegExp, `${t('schema.notMatchFormat')}`),
    message: yup.string().required(`${t('schema.required')}`)
  });

  const consultantNetworkSchema = yup.object({
    name: yup.string().required(`${t('schema.required')}`),
    email: yup.string().required(`${t('schema.requiredEmail')}`).email(`${t('schema.invalidEmail')}`),
    phone: yup.string().matches(phoneRegExp, `${t('schema.notMatchFormat')}`),
    country: yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    }),
    requirementDetails: yup.string(),
  });

  const contactRPFSchema = yup.object({
    documentRequirement: yup.mixed().nullable().required(`${t('schema.requiredFile')}`).test('documentRequirement', `${t('schema.notMatchFormat')}`, (documentRequirement: any) => {
      if (!documentRequirement) return true;
      return SUPPORTED_PDF_DOC.includes(documentRequirement.type);
    }),
    firstName: yup.string().required(`${t('schema.required')}`),
    lastName: yup.string().required(`${t('schema.required')}`),
    solutions: yup.array().required(`${t('schema.requiredSelect')}`).min(1, `${t('schema.atLeastOne')}`),
    email: yup.string().required(`${t('schema.requiredEmail')}`).email(`${t('schema.invalidEmail')}`),
    countryId: yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    }).default(undefined).required(`${t('schema.requiredSelect')}`),
  });

  return {
    requestTeamSchema,
    formGeneralContact,
    rfpContactSchema,
    openVacanciesDetailSchema,
    consultantNetworkSchema,
    contactRPFSchema,
  };
};

export default useSchemas;

import { AxiosError } from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';

import RenderPage from './RenderPage';

import usePreview from 'hooks/usePreview';
import Error from 'pages/Error';
import { PageDetail } from 'services/models';
import { getPageService } from 'services/navigations';

const PageNavigate: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const {
    data,
    error,
    isLoading
  } = usePreview(
    async () => getPageService(slug || ''),
    ['getPage', slug],
    !!slug,
  );

  if (isLoading && slug) {
    return null;
  }

  if (error) {
    const errorStt = (error as AxiosError)?.response?.status || 404;
    if (errorStt) {
      return (
        <Error statusCode={
          errorStt
        }
        />
      );
    }
    return null;
  }
  return <RenderPage pageData={data as PageDetail<any>} />;
};

export default PageNavigate;

import React, { useEffect, MutableRefObject } from 'react';

const useClickOutside = (
  ref: React.RefObject<any>[],
  callback: (event: MouseEvent) => void,
): void => {
  const handleClick = (e: MouseEvent): void => {
    if (!ref.some((refItem) => refItem.current
      && refItem.current.contains((e.target as Node || null)))) {
      callback(e);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);

    return (): void => {
      document.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref as MutableRefObject<any>[], callback]);
};

export default useClickOutside;

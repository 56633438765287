import {
  FooterBlockType, HeaderBlockType, PageStaticTypes, StickyMenuBlockType
} from './types';

import axiosInstance from 'services/common/instance';
import { HeaderFooter, SystemDetail } from 'services/models';

export const getHeaderFooterService = async ():
  Promise<HeaderFooter<HeaderBlockType, FooterBlockType, StickyMenuBlockType>> => {
  const res = await axiosInstance.get<APIResponse<HeaderFooter<HeaderBlockType, FooterBlockType, StickyMenuBlockType>>>('systems/header-footer');
  return res.data.data;
};

export const getSystemGeneraService = async (): Promise<SystemDetail> => {
  const response = await axiosInstance.get<APIResponse<SystemDetail>>('systems/general');
  return response.data.data;
};

export const getPageStaticService = async (): Promise<PageStaticTypes[]> => {
  const res = await axiosInstance.get('pages/static/all');
  return res.data?.data;
};

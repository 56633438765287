import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';

const useGaTracker = () => {
  const location = useLocation();
  const gaIds = useAppSelector((state) => state.system.systemData?.others?.gaIds);

  useEffect(() => {
    if (!gaIds) return;
    ReactGA4.initialize(gaIds[0]);
    gaIds.forEach((id, idx) => {
      if (idx > 0) {
        ReactGA4.ga('create', id, 'auto', { name: `GA-${id}` });
      }
    });
  }, [gaIds]);

  useEffect(() => {
    if (!gaIds) {
      return;
    }

    ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search });
    gaIds.forEach((id, idx) => {
      if (idx > 0) {
        ReactGA4.ga(`GA-${id}.send`, 'pageview', { page: location.pathname + location.search });
      }
    });
  }, [gaIds, location]);
};

export default useGaTracker;

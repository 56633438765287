import { OpeningVacanciesDetailParams } from './types';

import axiosInstance from 'services/common/instance';
import { JobDetail } from 'services/models';

export const getOpenVacanciesDetailService = async (
  slug: string,
): Promise<APIPaginationResponse<JobDetail>> => {
  const res = await axiosInstance.get(`recruitment-jobs/${slug}`);
  return res.data;
};

export const postOpeningVacanciesDetailService = async (
  data: OpeningVacanciesDetailParams,
): Promise<void> => {
  await axiosInstance.post('recruitment-applied-jobs', data);
};

export default undefined;

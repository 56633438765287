import axiosInstance from 'services/common/instance';
import { PageDetail } from 'services/models';

export const getStaticHomeService = async <T>(): Promise<PageDetail<T>> => {
  const response = await axiosInstance.get('pages/static/home-page');
  return response.data.data;
};

export const getPageService = async <T>(
  slug: string,
): Promise<PageDetail<T>> => {
  const response = await axiosInstance.get(`pages/${slug}`);
  return response.data.data;
};

export const getPreviewData = async <T,>(previewToken: string): Promise<T> => {
  const res = await axiosInstance.get(`pages/static/preview/${previewToken}`);
  return res.data.data;
};

import { ErrorBlock } from './types';

import axiosInstance from 'services/common/instance';

const getErrorService = async (): Promise<ErrorBlock> => {
  const response = await axiosInstance.get('systems/errors-pages');
  return response.data;
};

export default getErrorService;

import { MenuListItems } from 'services/models';

export const checkActiveMenu = (
  slug: string,
  setActive: (id: number) => void,
  setSubActive: (id: number) => void,
  menu?: MenuListItems[],
  preSlug?: string,
) => {
  const checkSlug = preSlug || slug;
  setActive(-1);
  menu?.every((e) => {
    if (e.subMenu?.length) {
      const menuItem = e.subMenu.find(
        (v) => v.reference?.slug && v.reference.slug.includes(checkSlug),
      );
      if (menuItem) {
        if (e.id) setActive(e.id);
        if (checkSlug && menuItem.id) setSubActive(menuItem.id);
        return false;
      }

      if (e.reference?.slug && e.reference?.slug.includes(checkSlug) && e.id) {
        setActive(e.id);
        return false;
      }

      return true;
    }

    if (e.reference?.slug && e.reference?.slug.includes(checkSlug) && e.id) {
      setActive(e.id);
      return false;
    }
    return true;
  });
};

export const returnRouteMenu = (menu: MenuListItems) => {
  if (menu.type === 'customLink' && menu.link) {
    return menu.link;
  }
  if (menu.reference?.slug) {
    return `/${menu.reference?.slug}`;
  }
  return '#';
};

/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useAppSelector } from 'store/hooks';

export interface ErrorsProps {
  statusCode: number;
}
const Error: React.FC<ErrorsProps> = ({ statusCode }) => {
  const { errorPages } = useAppSelector((state) => state.staticPage);

  const errorBlockData = useMemo(
    () => errorPages[`error${statusCode}`]?.blocks.information,
    [statusCode, errorPages],
  );

  return (
    <div className="main">
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="render:status_code" content={statusCode?.toString()} />
      </Helmet>
      <section id="fof" className="error404">
        <div className="container text-center">
          <div className="heading heading--gradient--3">
            <h2 className="heading__title">
              {
                errorBlockData?.code.data?.split('').map((item) => (
                  <span><strong>{item}</strong></span>
                ))
              }
            </h2>
          </div>
          <div className="heading heading--gradient--1 heading--divider">
            <h3><span className="heading__title" dangerouslySetInnerHTML={{ __html: errorBlockData?.title?.data || '' }} /></h3>
            <p className="heading__desc" dangerouslySetInnerHTML={{ __html: errorBlockData?.description?.data || '' }} />
          </div>
        </div>
      </section>
    </div>
  );
};
export default Error;

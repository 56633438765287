import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';

import Loading from 'components/atoms/Loading';
import MainLayout from 'components/templates/MainLayout';
import useInitApp from 'hooks/useInitApp';
import HomeNavigate from 'navigations/HomeNavigate';
import PageNavigate from 'navigations/PageNavigate';
import OpenVacanciesDetail from 'pages/OpenVacanciesDetail';
import { store } from 'store';

const App: React.FC = () => {
  useInitApp();
  return (
    <Suspense fallback={<Loading isShow variant="fullScreen" />}>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomeNavigate />} />
          <Route path=":slug" element={<PageNavigate />} />
          <Route path="/opening-vacancies/:slug" element={<OpenVacanciesDetail />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

const AppProvider: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;

import React from 'react';

import Link from '../Link';

import mapModifiers from 'utils/functions';

export const iconList = {
  search: 'search',
  play: 'play',
  pause: 'pause',
  volume: 'volume',
  volumeMute: 'volumeMute',
  arrowDownTriangle: 'arrowDownTriangle'
};

export type IconName = keyof typeof iconList;

export type IconSize = '6' | '8' | '12' | '16' | '20' | '24' | '32' | '40';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);
interface IconButtonProps extends IconProps {
  href?: string;
  target?: string;
  bgColor?: 'white' | 'transparent';
  buttonSize?: '24' | '30';
  handleClick?: () => void;
}

export const IconButton: React.FC<IconButtonProps> = ({
  iconName,
  size,
  href,
  target,
  bgColor,
  buttonSize,
  handleClick,
}) => {
  if (href) {
    return (
      <Link className={mapModifiers('a-iconButton', bgColor, buttonSize)} href={href} target={target} aria-label="Icon button">
        <Icon iconName={iconName} size={size} />
      </Link>
    );
  }
  return (
    <button className={mapModifiers('a-iconButton', bgColor, buttonSize)} type="button" onClick={handleClick} aria-label="Icon button">
      <Icon iconName={iconName} size={size} />
    </button>
  );
};

IconButton.defaultProps = {
  handleClick: undefined,
  href: undefined,
  target: undefined,
  size: undefined,
  bgColor: undefined,
  buttonSize: undefined,
};

Icon.defaultProps = {
  size: undefined,
};

export default Icon;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import { MenuListItems } from 'services/models';
import mapModifiers, { checkExternalUrl, getImageURL } from 'utils/functions';

interface FooterProps {
  footerMenu: MenuListItems[];
  footerInfo: {
    address?: string;
    email?: string;
    phones?: RepeaterPageTypes<{
      textPhone: TextPageTypes;
      numberPhone: TextPageTypes;
    }>,
  }
  socialHref?: {
    icon: UploadImagePageTypes;
    link: LinkPageTypes
  }[],
  stickyMenu?: {
    activeIcon: UploadImagePageTypes,
    inActiveIcon: UploadImagePageTypes,
    link: LinkPageTypes
  }[],
}

const Footer: React.FC<FooterProps> = ({
  footerMenu, footerInfo, socialHref, stickyMenu
}) => {
  const location = useLocation();
  const [isBottom, setIsBottom] = useState(false);
  const handleScroll = () => {
    const bottom = Math.ceil(
      window.innerHeight + window.scrollY
    ) >= (document.documentElement.scrollHeight - 81);

    setIsBottom(bottom);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="o-footer">
      <div className="bottom">
        <div className="container">
          <div className="row">
            {footerMenu?.map((item, idx) => (
              <div key={`item-${idx.toString()}`} className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <h2 className="bottom__title">{item.title}</h2>
                {item.subMenu && item.subMenu.length > 0 && (
                  <ul className="bottom__menu">
                    {item.subMenu && item.subMenu.map((sub, index) => (
                      <li key={`sub-item-${index.toString()}`}><Link href={sub.reference?.slug || ''}>{sub.title}</Link></li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <!-- / bottom --> */}
      {footerInfo && (
        <footer className="footer">
          <Link
            useExternal
            href="tel:+842822299097"
            customClassName={isBottom ? 'footer__call fixed' : 'footer__call'}
          >
            <i className="icon_phone" />
          </Link>
          <div className="container">
            <div className="footer__contact">
              <ul>
                <li>
                  <i className="icon_building" />
                  {' '}
                  {footerInfo.address}
                </li>
                <br />
                <li>
                  <i className="icon_mail" />
                  {' '}
                  <a href={`mailto:${footerInfo.email}`} aria-label={`mailto:${footerInfo.email || ''}`}>{footerInfo.email}</a>
                </li>
                {
                  footerInfo.phones && footerInfo.phones.data.length > 0 && (
                    <li>
                      <i className="icon_phone" />
                      {footerInfo.phones.data.map((item, idx) => (
                        <React.Fragment key={`phone-${idx.toString()}`}>
                          <a href={`tel:${item?.numberPhone.data}`} aria-label={`tel:${item?.numberPhone.data}`}>{(item?.textPhone.data)}</a>
                          {(footerInfo.phones && footerInfo.phones.data
                            && idx < footerInfo.phones.data.length - 1) && (<>&nbsp;or</>)}
                          &nbsp;
                        </React.Fragment>
                      ))}
                    </li>
                  )
                }
              </ul>
            </div>
            {socialHref && socialHref.length > 0 && (
              <div className="footer__social">
                {socialHref.map((item, idx) => (
                  <Link
                    useExternal
                    key={`social-item-${idx.toString()}`}
                    href={item.link.data.url}
                    target={item.link.data.target || '_blank'}
                  >
                    <div className="footer__social__icon">
                      <Image imgSrc={item.icon.data?.path || ''} alt={item.icon.data?.alt || 'social'} ratio="1x1" width={16} height={16} />
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </footer>
      )}
      {
        stickyMenu && stickyMenu.length > 0 && (
          <div className="stickyMenuMobile">
            {
              stickyMenu.map((item, idx) => (
                <div
                  className={mapModifiers('stickyMenuMobile__item', item.link.data.url?.includes(location.pathname) && 'active')}
                  style={{ width: `calc(${1 / stickyMenu.length} * 100%)` }}
                  key={`sticky-item-${idx.toString()}`}
                >
                  <Link
                    href={item?.link?.data?.url || ''}
                    useExternal={checkExternalUrl(item?.link?.data?.url)}
                    target={item?.link?.data?.target}
                  >
                    <img
                      src={item.link.data.url?.includes(location.pathname)
                        ? getImageURL(item.activeIcon.data?.path)
                        : getImageURL(item.inActiveIcon.data?.path)}
                      alt={item.inActiveIcon.data?.alt}
                    />
                  </Link>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};
Footer.defaultProps = {
};

export default Footer;

/* eslint-disable react/no-danger */
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, {
  useMemo, useRef, useState
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Breadcrumb from 'components/molecules/Breadcrumb';
import HelmetContainer from 'components/templates/MainLayout/Helmet';
import UploadFile from 'components/templates/RfpContact/uploadFile';
import useScrollAnimate from 'hooks/useScrollAnimation';
import i18n from 'i18n';
import { TEMPLATE_CODE_CONFIG } from 'navigations';
import Error from 'pages/Error';
import { JobDetailTranslationsVi } from 'services/models';
import { JobDetailTranslations } from 'services/models/job-detail-translations';
import { getPageService } from 'services/navigations';
import { getOpenVacanciesDetailService, postOpeningVacanciesDetailService } from 'services/openingVacanciesDetail';
import { useAppSelector } from 'store/hooks';
import { URL_CONST } from 'utils/constants';
import { checkExternalUrl } from 'utils/functions';
import useSchemas from 'utils/schemas';

type OpenVacanciesDetailReturnTypes = {
  file?: File;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  message: string;
};

type OpenVacanciesBlock = {
  bannerPageSection: {
    image: UploadImagePageTypes
    titleBanner: TextPageTypes
    textButton: TextPageTypes
    formTitle: TextPageTypes
  }
  cta: {
    image: UploadImagePageTypes
    link: LinkPageTypes
    title: TextPageTypes
  }
};

const OpenVacanciesDetail: React.FC = () => {
  const ctaSectionRef = useRef<HTMLDivElement>(null);
  const ctaSectionAnimate = useScrollAnimate(ctaSectionRef);
  const [status, setStatus] = useState<StatusTypes | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { openVacanciesDetailSchema } = useSchemas();
  const { slug } = useParams<{ slug: string }>();
  const staticPages = useAppSelector((state) => state.system.staticPage);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const googleRecaptchaKey = useAppSelector((state) => state.system.systemData?.googleRecaptchaKey);
  const navigate = useNavigate();
  const method = useForm<OpenVacanciesDetailReturnTypes>({
    resolver: yupResolver(openVacanciesDetailSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      file: undefined,
      firstName: '',
      lastName: '',
      message: '',
      phone: ''
    }
  });

  const breadcrumbDataDetail = (
    translations?: JobDetailTranslationsVi,
    breadcrumbs?: BreadcrumbData[]
  ) => {
    const homeBreadcrumb = {
      title: 'Home',
      href: '/'
    };
    const currentBreadCrumb = {
      title: translations?.name || '',
    };
    if (breadcrumbs && breadcrumbs.length > 0) {
      const formatBreadcrumb = breadcrumbs.map((item) => ({
        title: item.text || '',
        href: `/${item.slug}`
      }));
      return [homeBreadcrumb, ...formatBreadcrumb, currentBreadCrumb];
    }

    return [homeBreadcrumb, currentBreadCrumb];
  };

  const openVacanciesDetailPage = useMemo(() => staticPages?.find(
    (page) => page.pageData.code === TEMPLATE_CODE_CONFIG.OPEN_VACANCIES_DETAIL_PAGE,
  )?.pageData?.slug, [staticPages]) || '';

  const { data: getPageServiceData } = useQuery(
    ['openVacanciesDetailPage', openVacanciesDetailPage],
    () => getPageService<OpenVacanciesBlock>(openVacanciesDetailPage),
    {
      enabled: !!openVacanciesDetailPage,
    },
  );

  const { data: openVacanciesDetail, error: openVacanciesError } = useQuery(
    ['getOpenVacanciesDetailService', { slug }],
    () => getOpenVacanciesDetailService(slug || ''),
    {
      enabled: !!slug,
    },
  );
  // Upload File
  const uploader = new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: 1000000,
      success: {
        jsonPayload: true,
        endpoint: URL_CONST.MEDIA_FILE_MERGE_CHUNK,
        params(fileId: number) {
          const uuid = uploader.getUuid(fileId);
          const fileName = (uploader.getFile(fileId) as File).name;
          return {
            qquuid: uuid,
            qqfilename: fileName,
          };
        }
      },
    },
    callbacks: {
      onComplete: async (id: number, name: string, res: {
        success: boolean;
        data?: { path: string };
      }) => {
        recaptchaRef.current?.reset();
        const tokenCapche = await recaptchaRef.current?.executeAsync();
        const dataSubmit = method.getValues();
        await postOpeningVacanciesDetailService(
          {
            ...dataSubmit,
            linkCV: res?.data?.path,
            jobId: openVacanciesDetail?.data.jobData?.id,
            ggRecaptchaToken: tokenCapche || ''
          }
        );
        method.reset();
        setStatus('success');
      },
    }
  });

  const handleSubmit = async (data: OpenVacanciesDetailReturnTypes) => {
    try {
      setIsLoading(true);
      if (data.file) {
        await uploader.addFiles(data.file);
        await uploader.uploadStoredFiles();
      }
    } catch (error) {
      setStatus('fail');
    } finally {
      setIsLoading(false);
      setTimeout(() => setStatus(undefined), 3000);
    }
  };

  if (openVacanciesError) {
    const errorStt = (openVacanciesError as AxiosError)?.response?.status || 404;
    if (errorStt) {
      return (
        <Error statusCode={
          errorStt
        }
        />
      );
    }
    return null;
  }

  return (
    <div className="page-openingVacancies page-openingVacancies--detail">
      <HelmetContainer
        ogData={openVacanciesDetail?.data?.ogData}
        seoData={openVacanciesDetail?.data?.seoData}
      />
      <section
        className="section-subheader section-subheader--career"
        style={
          { backgroundImage: `url(${getPageServiceData?.blocks?.bannerPageSection?.image?.data?.path})` }
        }
      >
        <div className="container">
          <div className="heading heading--gradient heading--gradient--9">
            <h2 className="heading__title">{getPageServiceData?.blocks?.bannerPageSection?.titleBanner?.data}</h2>
          </div>
          <Breadcrumb breadcrumbs={breadcrumbDataDetail(
            openVacanciesDetail?.data?.translations[i18n.language as keyof JobDetailTranslations],
            getPageServiceData?.breadcrumb
          )}
          />
        </div>
        <div className="section-description__bottom" />
      </section>
      <section className="section-careerDetail">
        <div className="container">
          <div className="careerDetail">
            <div className="careerDetail__inner">
              <div className="row">
                <div className="col-md-12 col-md-offset-0 col-lg-10 col-lg-offset-1">
                  <Link
                    handleClick={() => navigate(-1)}
                    customClassName="careerDetail__all"
                  >
                    {getPageServiceData?.blocks?.bannerPageSection?.textButton?.data}
                  </Link>
                  <h2 className="careerDetail__title">
                    {openVacanciesDetail?.data
                      ?.translations[i18n.language as keyof JobDetailTranslations]
                      ?.name}
                  </h2>
                  {openVacanciesDetail?.data?.jobLevels && (
                    <div className="text-center">
                      {openVacanciesDetail?.data?.jobLevels?.map((item, idx) => (
                        <span
                          key={`level-item-${idx.toString()}`}
                          className={`careerDetail__badges careerDetail__badges--${item?.name?.toLowerCase()}`}
                        >
                          {item?.name}
                        </span>
                      ))}
                    </div>
                  )}
                  <p
                    className="careerDetail__desc"
                    style={{ textAlign: 'justify' }}
                    dangerouslySetInnerHTML={
                      { __html: openVacanciesDetail?.data?.jobData?.description || '' }
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-md-offset-0 col-lg-6 col-lg-offset-3">
                  <FormProvider {...method}>
                    <form className="careerDetail__form form" onSubmit={method.handleSubmit(handleSubmit)}>
                      <h3 className="careerDetail__form__head">{getPageServiceData?.blocks?.bannerPageSection?.formTitle?.data}</h3>
                      <div className="">
                        <div className={`alert alert-success animate fade ${status === 'success' ? 'fadeIn' : 'ng-hide'}`} role="alert">
                          Your message is
                          successfully sent! Thank you!
                        </div>
                        <div className={`alert alert-danger animate fade ${status === 'fail' ? 'fadeIn' : 'ng-hide'}`} role="alert">
                          Your message is not sent
                          yet. Please retry or try again later, thank you.
                        </div>
                      </div>
                      <Controller
                        name="firstName"
                        render={({ field, fieldState: { error } }) => (
                          <div className="form-group">
                            <input
                              {...field}
                              type="text"
                              className="form-control"
                              name="first_name"
                              placeholder="First name *"
                            />
                            {error && (
                              <div className="form-control_error">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                      <Controller
                        name="lastName"
                        render={({ field, fieldState: { error } }) => (
                          <div className="form-group">
                            <input
                              {...field}
                              type="text"
                              className="form-control"
                              name="last_name"
                              placeholder="Last name *"
                            />
                            {error && (
                              <div className="form-control_error">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                      <Controller
                        name="phone"
                        render={({ field, fieldState: { error } }) => (
                          <div className="form-group">
                            <input
                              {...field}
                              type="text"
                              className="form-control"
                              name="phone"
                              placeholder="Phone"
                            />
                            {error && (
                              <div className="form-control_error">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                      <Controller
                        name="email"
                        render={({ field, fieldState: { error } }) => (
                          <div className="form-group">
                            <input
                              {...field}
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email *"
                            />
                            {error && (
                              <div className="form-control_error">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                      <Controller
                        name="message"
                        render={({ field, fieldState: { error } }) => (
                          <div className="form-group">
                            <textarea
                              {...field}
                              className="form-control"
                              name="message"
                              id=""
                              rows={4}
                              placeholder="Message"
                            />
                            {error && (
                              <div className="form-control_error">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                      <Controller
                        name="file"
                        render={({ field, fieldState: { error } }) => (
                          <div className="form-group">
                            <UploadFile
                              {...field}
                            />
                            {error && (
                              <div className="form-control_error">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                      <div className="form-group">
                        <Button
                          variant="maxWith150"
                          loading={isLoading}
                          type="submit"
                          aria-label="Apply now"
                        >
                          APPLY NOW
                        </Button>
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-cta section-cta--career" ref={ctaSectionRef}>
        <div className="container">
          <div
            className={
              `section-cta__inner animate${ctaSectionAnimate ? ' cardAnimating' : ''}`
            }
            style={{
              backgroundImage:
                `url(${getPageServiceData?.blocks?.cta?.image?.data?.path})`,
              animationDelay: '200ms'
            }}
          >
            <h3>{getPageServiceData?.blocks?.cta?.title?.data}</h3>
            <Link
              href={getPageServiceData?.blocks?.cta?.link?.data?.url}
              useExternal={checkExternalUrl(getPageServiceData?.blocks?.cta?.link?.data?.url)}
              target={getPageServiceData?.blocks?.cta?.link?.data?.target}
              customClassName="btn btn-3forcom"
              title="we are hiring"
            >
              {getPageServiceData?.blocks?.cta?.link?.data?.text}
            </Link>
          </div>
        </div>
      </section>
      {googleRecaptchaKey
        && (
          <ReCAPTCHA
            sitekey={googleRecaptchaKey}
            size="invisible"
            ref={recaptchaRef}
          />
        )}
    </div>
  );
};

export default OpenVacanciesDetail;

import React from 'react';

import { PageDetail } from 'services/models';

const About = React.lazy(() => import('pages/About'));
const AgileDevelopment = React.lazy(() => import('pages/AgileDevelopment'));
const BusinessApps = React.lazy(() => import('pages/BusinessApps'));
const Careers = React.lazy(() => import('pages/Careers'));
const ConsultingNetwork = React.lazy(() => import('pages/ConsultingNetwork'));
const GeneralContact = React.lazy(() => import('pages/Contact'));
const ContactRfp = React.lazy(() => import('pages/ContactRfp'));
const CpaDevelopment = React.lazy(() => import('pages/CpaDevelopment'));
const DedicatedDevTeam = React.lazy(() => import('pages/DedicatedDevTeam'));
const Ecommerce = React.lazy(() => import('pages/Ecommerce'));
const FaQsPage = React.lazy(() => import('pages/FaQs'));
const MobileApplication = React.lazy(() => import('pages/MobileApplication'));
const MobileDevelopment = React.lazy(() => import('pages/MobileDevelopment'));
const OpenVacancies = React.lazy(() => import('pages/OpenVacancies'));
const OpenVacanciesDetail = React.lazy(() => import('pages/OpenVacanciesDetail'));
const OurClientele = React.lazy(() => import('pages/OurClientele'));
const RequestTeam = React.lazy(() => import('pages/RequestTeam'));
const SeoService = React.lazy(() => import('pages/SeoService'));
const Services = React.lazy(() => import('pages/Services'));
const Solutions = React.lazy(() => import('pages/Solutions'));
const SystemMaintenance = React.lazy(() => import('pages/SystemMaintenance'));
const Home = React.lazy(() => import('pages/Home'));
const WorkDetail = React.lazy(() => import('pages/WorkDetail'));
const Works = React.lazy(() => import('pages/Work'));
const WebsiteMicrosite = React.lazy(() => import('pages/WebsiteMicrosite'));
const WebsiteDevelopment = React.lazy(() => import('pages/WebDevelopment'));
const UiUxDesign = React.lazy(() => import('pages/UiUxDesign'));
const TechnicalConsulting = React.lazy(() => import('pages/TechnicalConsulting'));

export type TemplateCodeType = {
  templateCode?: string;
  component: React.FC<PageDetail<any>>;
};

export const TEMPLATE_CODE_CONFIG = {
  HOME_PAGE: 'HOME_PAGE',
  E_COMMERCE: 'E_COMMERCE',
  WEBSITE_MICROSITE: 'WEBSITE_MICROSITE',
  DEDICATEDDEVTEAM_PAGE: 'DEDICATEDDEVTEAM_PAGE',
  BUSINESSAPPS_PAGE: 'BUSINESSAPPS_PAGE',
  SERVICES: 'SERVICES',
  SYSTEM_MAINTENANCE: 'SYSTEM_MAINTENANCE',
  MOBILE_APPLICATION: 'MOBILE_APPLICATION',
  SEO_SERVICE: 'SEO_SERVICE',
  SOLUTION: 'SOLUTION',
  CPA_DEVELOPMENT: 'CPA_DEVELOPMENT',
  UIUX_DESIGN: 'UIUX_DESIGN',
  OUR_CLIENTELE: 'OUR_CLIENTELE',
  MOBILE_DEVELOPMENT: 'MOBILE_DEVELOPMENT',
  WEB_DEVELOPMENT: 'WEB_DEVELOPMENT',
  ABOUT_PAGE: 'ABOUT_PAGE',
  AGILE_DEVELOPMENT: 'AGILE_DEVELOPMENT',
  TECHNICAL_CONSULTING: 'TECHNICAL_CONSULTING',
  CONSULTING_NETWORK_PAGE: 'CONSULTING_NETWORK_PAGE',
  OPEN_VACANCIES_PAGE: 'OPEN_VACANCIES_PAGE',
  WORK_PAGE: 'WORK_PAGE',
  OPEN_VACANCIES_DETAIL_PAGE: 'OPEN_VACANCIES_DETAIL_PAGE',
  CAREERS_PAGE: 'CAREERS_PAGE',
  FAQS: 'FAQS',
  CONTACT: 'CONTACT',
  WORK_DETAIL: 'WORK_DETAIL',
  REQUEST_TEAM_PAGE: 'REQUEST_TEAM_PAGE',
  CONTACTRFP: 'CONTACTRFP'
};

export const TemplateCodes: TemplateCodeType[] = [
  {
    templateCode: TEMPLATE_CODE_CONFIG.HOME_PAGE,
    component: Home,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.SOLUTION,
    component: Solutions,
  },
  {

    templateCode: TEMPLATE_CODE_CONFIG.E_COMMERCE,
    component: Ecommerce,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.MOBILE_APPLICATION,
    component: MobileApplication,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.WEBSITE_MICROSITE,
    component: WebsiteMicrosite,
  },
  {

    templateCode: TEMPLATE_CODE_CONFIG.DEDICATEDDEVTEAM_PAGE,
    component: DedicatedDevTeam,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.BUSINESSAPPS_PAGE,
    component: BusinessApps,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.SERVICES,
    component: Services,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.SYSTEM_MAINTENANCE,
    component: SystemMaintenance,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.SEO_SERVICE,
    component: SeoService,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.CPA_DEVELOPMENT,
    component: CpaDevelopment,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.MOBILE_DEVELOPMENT,
    component: MobileDevelopment,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.UIUX_DESIGN,
    component: UiUxDesign,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.OUR_CLIENTELE,
    component: OurClientele,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.WEB_DEVELOPMENT,
    component: WebsiteDevelopment,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.AGILE_DEVELOPMENT,
    component: AgileDevelopment,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.ABOUT_PAGE,
    component: About,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.TECHNICAL_CONSULTING,
    component: TechnicalConsulting,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.WORK_PAGE,
    component: Works,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.CONSULTING_NETWORK_PAGE,
    component: ConsultingNetwork,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.OPEN_VACANCIES_PAGE,
    component: OpenVacancies,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.CAREERS_PAGE,
    component: Careers,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.FAQS,
    component: FaQsPage,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.CONTACT,
    component: GeneralContact,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.WORK_DETAIL,
    component: WorkDetail
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.OPEN_VACANCIES_DETAIL_PAGE,
    component: OpenVacanciesDetail,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.REQUEST_TEAM_PAGE,
    component: RequestTeam,
  },
  {
    templateCode: TEMPLATE_CODE_CONFIG.CONTACTRFP,
    component: ContactRfp,
  },
];

import useDidMount from './useDidMount';
import useGaTracker from './useGaTracker';
import useTagManager from './useTagManager';

import { useAppDispatch } from 'store/hooks';
import { getMenuDataAsync } from 'store/menu';
import { getErrorPageAction } from 'store/staticPage';
import {
  getHeaderFooterAsync, getStaticPageAsync, getSystemGeneralAsync, initialDone
} from 'store/system';
import { delay } from 'utils/functions';

const useInitApp = () => {
  const dispatch = useAppDispatch();
  useDidMount(() => {
    dispatch(getSystemGeneralAsync()).unwrap();
    dispatch(getMenuDataAsync()).unwrap();
    dispatch(getHeaderFooterAsync()).unwrap();
    dispatch(getStaticPageAsync()).unwrap();
    dispatch(getErrorPageAction()).unwrap();
    delay(2000).then(() => {
      dispatch(initialDone());
    });
  });

  useGaTracker();
  useTagManager();
};

export default useInitApp;

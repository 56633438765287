import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import mapModifiers from 'utils/functions';

type SizeImageType = 'cover' | 'contain' | 'inherit' | 'initial';

interface ImageProps {
  imgSrc: string;
  alt?: string;
  ratio?: RatioImage;
  size?: SizeImageType;
  width?: number;
  height?: number;
}

const Image: React.FC<ImageProps> = ({
  imgSrc, alt, ratio, size, width, height
}) => {
  const [loading, setLoading] = useState(true);
  return (
    <div className={mapModifiers('a-image', size, ratio && `${ratio}`, loading && 'loading')}>
      <LazyLoadImage
        alt={alt}
        effect="black-and-white"
        src={imgSrc}
        afterLoad={() => setLoading(false)}
        {...width ? ({ width }) : undefined}
        {...height ? ({ height }) : undefined}
      />
    </div>
  );
};

Image.defaultProps = {
  alt: undefined,
};

export default Image;

import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// import { LoadingPage } from 'components/atoms/Loading';
import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import { useAppSelector } from 'store/hooks';
import { groupMenus } from 'utils/functions';

const MainLayout: React.FC = () => {
  const headerFooter = useAppSelector((state) => state.system.headerFooter);
  const menus = useAppSelector((state) => state.menu.menus);
  // const initLoading = useAppSelector((state) => state.system.loadingInit);

  const headerData = useMemo(() => headerFooter
    && headerFooter.header?.blocks?.section1, [headerFooter]);
  const footerData = useMemo(() => headerFooter
    && headerFooter.footer?.blocks?.section1, [headerFooter]);
  const stickyMenu = useMemo(() => headerFooter
    && headerFooter.stickyMenu?.blocks?.section, [headerFooter]);

  const menuHeader = useMemo(() => {
    const data = menus.find((
      item
    ) => item.code === headerFooter?.header.blocks?.section1.menu.data);
    if (!data) {
      return [];
    }
    return groupMenus(data.items);
  }, [menus, headerFooter]);

  const menuFooter = useMemo(() => {
    const data = menus.find((
      item
    ) => item.code === headerFooter?.footer.blocks?.section1.menu.data);
    if (!data) {
      return [];
    }
    return groupMenus(data.items);
  }, [menus, headerFooter]);

  return (
    <>
      {/* {initLoading && <LoadingPage />} */}
      <Header
        logoSrc={headerData?.logo.data?.path}
        menuData={menuHeader}
        RFPLink={{
          href: headerData?.submitBtn.data.url,
          text: headerData?.submitBtn.data.text
        }}
        handleSearch={(v) => console.log(v)}
      />
      <div className="main">
        <Outlet />
      </div>
      <Footer
        footerMenu={menuFooter}
        footerInfo={{
          address: '39/3 Tran Nhat Duat, Tan Dinh Ward, District 1, Ho Chi Minh City' || footerData?.address.data,
          email: 'contact@drdigital.vn' || footerData?.email.data?.mailToSend,
          phones: footerData?.phones,
        }}
        socialHref={footerData?.socials.data}
        stickyMenu={stickyMenu?.menu?.data}
      />
    </>
  );
};

export default MainLayout;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { LOCAL_STORAGE } from 'utils/constants';

// import en from 'common/translations/en.json';
// import vi from 'common/translations/vi.json';

export const getCurrentLanguage = () => getLocalStorage(LOCAL_STORAGE.LANGUAGE) || 'en';

export const i18ChangeLanguage = (language: string) => {
  i18n.changeLanguage(language);
  setLocalStorage(LOCAL_STORAGE.LANGUAGE, language);
  window.location.reload();
};

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .use(new LanguageDetector(null, { lookupLocalStorage: LOCAL_STORAGE.LANGUAGE }))
  .init({
    fallbackLng: 'en',
    lng: getCurrentLanguage(),
    // resources: {
    //   vi: {
    //     translation: vi,
    //   },
    //   en: {
    //     translation: en
    //   }
    // },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_BASE_URL}fe-translations?locale={{lng}}`,
    },
    //! Only turn this defaultNS on when testing local static translation files
    // defaultNS: 'local',
  });

export default i18n;

import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useAppSelector } from 'store/hooks';

const useTagManager = () => {
  const gtmId = useAppSelector((state) => state.system.systemData?.others?.gtmIds);

  useEffect(() => {
    if (!gtmId) return;
    TagManager.initialize({
      gtmId: gtmId[0],
    });
  }, [gtmId]);
};

export default useTagManager;

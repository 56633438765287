import { MenuListItems, PageDetailTranslationsVi } from 'services/models';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const baseURL = (src?: string): string => `${BASE_URL || ''}${src || ''}`;
function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - 68 },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export const checkExternalUrl = (str?: string) => {
  if (!str) return false;
  const tareaRegex = /^(http|https|tel)/;
  return tareaRegex.test(String(str).toLowerCase());
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const groupMenus = (menus?: MenuListItems[]) => {
  if (!menus) {
    return [];
  }
  const recursiveMenus = (
    menuList: MenuListItems[],
    parentId?: number,
  ): MenuListItems[] => {
    const menusGrouped: MenuListItems[] = [];
    menuList.forEach((menu) => {
      if (menu.parentId === parentId) {
        const subMenus = recursiveMenus(menuList, menu.id);
        menusGrouped.push(
          subMenus.length > 0
            ? {
              ...menu,
              subMenu: subMenus,
            }
            : {
              ...menu,
            },
        );
      }
    });
    return menusGrouped;
  };
  if (menus.length > 0) {
    const firstLevelParentId = menus.find((menu) => menu.depth === 1)!.parentId;
    return recursiveMenus(menus, firstLevelParentId);
  }
  return [];
};

export const mappingURLToExternal = (path?: string) => (path ? new URL(path, window.location.origin).href : '');

export function getImageURL(imgUrl?: string) {
  if (!imgUrl) return '';
  return (BASE_URL ?? '') + imgUrl;
}

export const delay = async (timeout: number) => new Promise((resolve) => setTimeout(
  () => { resolve(true); },
  timeout,
));

export const breadcrumbData = (
  translations?: PageDetailTranslationsVi,
  breadcrumbs?: BreadcrumbData[]
) => {
  // Update translation with lang later
  const homeBreadcrumb = {
    title: 'Home',
    href: '/'
  };
  // Update translation with lang later
  const currentBreadCrumb = {
    title: translations?.title || '',
    href: `/${translations?.slug}`
  };
  if (breadcrumbs && breadcrumbs.length > 0) {
    const formatBreadcrumb = breadcrumbs.map((item) => ({
      title: item.text || '',
      href: `/${item.slug}`
    }));
    return [homeBreadcrumb, ...formatBreadcrumb, currentBreadCrumb];
  }

  return [homeBreadcrumb, currentBreadCrumb];
};

export const convertFormatFields = (fields: any) => {
  let newFields = {};
  if (typeof fields === 'object') {
    Object.keys(fields).forEach((item) => {
      if (fields?.[item] && Array.isArray(fields?.[item])) {
        const newData: String[] = [];
        const clone = fields?.[item] || [];

        clone.forEach((itemClone: any) => {
          const dataObj = Object.values(itemClone)[0] as any;
          if (typeof itemClone === 'object' && dataObj) {
            const dataString = dataObj.toString();
            if (dataString) newData.push(dataString);
          } else {
            newData.push(String(itemClone));
          }
        });
        newFields = { ...newFields, [item]: newData };
      } else if (typeof fields?.[item] === 'object') {
        const value = fields?.[item]?.value;
        newFields = { ...newFields, [item]: value };
      } else {
        newFields = { ...newFields, [item]: fields?.[item] };
      }
    });
    return newFields;
  }
  return newFields;
};

export const checkPrefixNumber = (value: number) => {
  if (value > 9) return value.toString();
  return `0${value}`;
};

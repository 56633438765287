import { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import RenderPage from './RenderPage';

import Error from 'pages/Error';
import { getStaticHomeService } from 'services/navigations';

const HomeNavigate: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const {
    data,
    isLoading,
    error,
    isError,
  } = useQuery(
    ['getStaticHomePage', slug],
    () => getStaticHomeService(),
    {
      enabled: !slug,
    },
  );

  if (isLoading) {
    return null;
  }

  if (error || isError) {
    const errorStt = (error as AxiosError)?.response?.status || 404;
    if (errorStt) {
      return (
        <Error statusCode={
          errorStt
        }
        />
      );
    }
    return null;
  }

  return <RenderPage pageData={data} />;
};

export default HomeNavigate;

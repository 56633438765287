import React from 'react';

import Link from 'components/atoms/Link';
import { checkExternalUrl } from 'utils/functions';

export type BreadcrumbPropsType = {
  href?: string;
  title: string;
};

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbPropsType[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => (
  <ul className="breadcrumbs">
    {breadcrumbs.map((item, idx) => (
      item.href && (idx < breadcrumbs.length - 1) ? (
        <li key={`breadcrumbs-${String(idx)}`}>
          <Link href={item.href} useExternal={checkExternalUrl(item.href)}>
            {item.title}
          </Link>
        </li>
      ) : (
        <li key={`breadcrumbs-${String(idx)}`}>
          {item.title}
        </li>
      )
    ))}
  </ul>
);

Breadcrumb.defaultProps = {};

export default Breadcrumb;

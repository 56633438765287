export const LOCAL_STORAGE = {
  LANGUAGE: '3FC_Language',
};
export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: '/api/v1/recruitment-applied-jobs/upload-cv',
  MEDIA_FILE_MERGE_CHUNK: '/api/v1/recruitment-applied-jobs/merge-chunk'
};
export const URL_CONST_CONTACT_RFP = {
  MEDIA_FILE_UPLOAD_CHUNK: '/api/v1/contact-rfps/upload',
  MEDIA_FILE_MERGE_CHUNK: '/api/v1/contact-rfps/merge-chunk'
};

export const test = null;

import React from 'react';

import mapModifiers from 'utils/functions';

interface LoadingProps {
  variant?: 'fullScreen' | 'default';
  isShow?: boolean;
  isFill?: boolean;
  size?: 'small' | 'default';
  isWhite?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  isShow = false,
  variant,
  isFill,
  size,
  isWhite
}) => {
  if (!isShow) return null;
  return (
    <div className={mapModifiers('a-loading', variant, isFill && 'filled', size, isWhite && 'white')}>
      <div className="a-loading_wrapper">
        <div className={mapModifiers('a-loading_tail', size)} />
      </div>
    </div>
  );
};

Loading.defaultProps = {
  variant: 'default',
  isShow: false,
  isFill: false,
  size: 'default',
};

export const LoadingPage: React.FC = () => (
  <div className="fc-loading">
    <div className="fc-loading__3fc">
      <div className="fc-loading__3fc__item--1 fc-loading__3fc__item" />
      <div className="fc-loading__3fc__item--2 fc-loading__3fc__item" />
      <div className="fc-loading__3fc__item--3 fc-loading__3fc__item" />
      <div className="fc-loading__3fc__item--4 fc-loading__3fc__item" />
      <div className="fc-loading__3fc__item--5 fc-loading__3fc__item" />
      <div className="fc-loading__3fc__item--6 fc-loading__3fc__item" />
    </div>
  </div>
);

export default Loading;
